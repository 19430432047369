import React, { ReactElement, useEffect, useState } from "react";
import SEO from "../components/seo";
import { isAuthed } from "../utils/auth";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import Cookies from "js-cookie";
import { apiBaseUrl } from "../constants";
import useAccount from '../hooks/useAccount';

// const plans = {
//     basic: "P-4KA61536G77345409MBEQVQQ",
//     pro: "P-5NL94409MC915580UMBF72RI",
// };
const plans = {
    basic: "P-7P8098247P972952EMBET4FA",
    pro: "P-9R026375TH8352458MBGBDAA",
};

const updateSubscriptionId = (id: string) => {
    const headers = new Headers();
    headers.append("Authorization", `Bearer ${Cookies.get("access_token")}`);

    var raw = JSON.stringify({ id });

    var requestOptions = {
        method: "POST",
        headers,
        body: raw,
    };

    fetch(`${apiBaseUrl}/api/user/updatesubscription`, requestOptions)
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log("error", error));
};

const Paypal = (props) => {
    const onErrorPaypal = (err: any) => {
        console.log(err);
    };

    const onApprove = (data) => {
        updateSubscriptionId(data.subscriptionID);
    }

    useEffect(() => {
        if (typeof window !== `undefined`) {
            window.paypal
                .Buttons({
                    ...props,
                    onApprove,
                    onError: props.onError ? props.onError : onErrorPaypal,
                    createSubscription: (data, actions) => {
                        console.log("data", data);
                        return actions.subscription.create({
                            plan_id: plans["basic"],
                        });
                    },
                })
                .render(`#paypal-basic-button`);
            window.paypal
                .Buttons({
                    ...props,
                    createSubscription: (data, actions) => {
                        console.log(data);
                        return actions.subscription.create({
                            plan_id: plans["pro"],
                        });
                    },
                    onApprove,
                    onError: props.onError ? props.onError : onErrorPaypal,
                })
                .render(`#paypal-pro-button`);
        }
    }, []);

    return (
        <div id="smart-button-container">
            <div>
                <div
                    id="paypal-basic-button"
                    className={props.plan === "basic" ? "" : "hidden"}
                ></div>
                <div
                    id="paypal-pro-button"
                    className={props.plan === "pro" ? "" : "hidden"}
                ></div>
            </div>
        </div>
    );
};

Paypal.propTypes = {
    plan: PropTypes.string,
    amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    currency: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    shippingPreference: PropTypes.string,
    onSuccess: PropTypes.func,
    onError: PropTypes.func,
    createOrder: PropTypes.func,
    createSubscription: PropTypes.func,
    onApprove: PropTypes.func,
    style: PropTypes.object,
};
Paypal.defaultProps = {
    style: {},
    currency: "USD",
    shippingPreference: "GET_FROM_FILE",
};

const Upgrade = ({
    location,
}: {
    state: { plan: keyof typeof plans };
}): ReactElement => {
    const [plan, setPlan] = useState<keyof typeof plans>(
        location.state?.plan ?? "basic"
    );

    const [user, loading, error] = useAccount();


    const [hover, setHover] = useState<"none" | keyof typeof plans>("none");

    const planDetailsClassnames =
        "m-8 p-4 bg-lightGray rounded-lg shadow-md flex flex-col items-center transition duration-200";

    const auth = user !== undefined;
    return (
        <>
            {!auth && (
                <>
                    <h1>Login to view this page</h1>
                    <Link to="/signup">Signup or Login</Link>
                </>
            )}
            <SEO
                title="Upgrade"
                description="Upgrade to Streamlux Basic or Pro plans to unleash the power of Streamlux"
            />

            <div className="flex flex-col items-center p-0 pt-8 h-screen max-h-screen">
                <div className="grid grid-cols-3 gap-8 flex-grow">
                    <div
                        className={
                            planDetailsClassnames +
                            (hover === "basic" ? " shadow-xl" : " shadow-sm")
                        }
                    >
                        <h1 className="font-display text-2xl font-semibold">
                            Streamlux Basic
                        </h1>
                        <h2 className="font-display text-lg">
                            Tenetur quod quidem in voluptatem corporis dolorum
                            dicta sit pariatur basic!
                        </h2>
                    </div>
                    <div>
                        <div className="font-semibold font-display text-center text-black">
                            <Link to="/">
                                <img
                                    src={require("assets/images/logo_dark.svg")}
                                    alt="Streamlux logo"
                                    className="w-1/2 mx-auto"
                                />
                            </Link>
                            <h1 className="mt-4 text-2xl">Select plan</h1>
                        </div>
                        <div className="grid grid-cols-2 gap-8 max-w-2xl m-8 mt-4 text-black">
                            <div>
                                <label
                                    className={`transition duration-200 shadow-md flex flex-row items-start p-4 rounded-lg ${
                                        plan === "basic"
                                            ? "border-2 border-primary bg-white"
                                            : "bg-lightGray border-2 border-transparent shadow-lg"
                                    }`}
                                    style={{ cursor: "pointer" }}
                                    onMouseEnter={() => {
                                        setHover("basic");
                                    }}
                                    onMouseLeave={() => {
                                        setHover("none");
                                    }}
                                >
                                    <input
                                        type="radio"
                                        className="form-radio h-6 w-6 self-center text-primary transition duration-200"
                                        name="radio-sizes"
                                        value="2"
                                        defaultChecked={
                                            location.state?.plan === "basic"
                                        }
                                        onClick={() => setPlan("basic")}
                                    />
                                    <span className="pl-4 text-lg p-8 w-full flex flex-col font-semibold">
                                        <h2 className="text-2xl">Basic</h2>
                                        <p>$3.99/mo</p>
                                    </span>
                                </label>
                            </div>
                            <div>
                                <label
                                    className={`transition duration-200 shadow-md flex flex-row items-start hover:bg-gray-100 p-4 rounded-lg ${
                                        plan === "pro"
                                            ? "border-2 border-primary bg-white"
                                            : "bg-lightGray border-2 border-transparent shadow-lg"
                                    }`}
                                    style={{ cursor: "pointer" }}
                                    onMouseEnter={() => {
                                        setHover("pro");
                                    }}
                                    onMouseLeave={() => {
                                        setHover("none");
                                    }}
                                >
                                    <input
                                        type="radio"
                                        className="form-radio h-6 w-6 self-center text-primary transition duration-200"
                                        style={{ outline: "none" }}
                                        name="radio-sizes"
                                        defaultChecked={
                                            location.state?.plan === "pro"
                                        }
                                        value="2"
                                        onClick={() => setPlan("pro")}
                                    />
                                    <span className="pl-4 text-lg p-8 w-full flex flex-col font-semibold">
                                        <h2 className="text-2xl">Pro</h2>
                                        <p>$7.99/mo</p>
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div
                        className={
                            planDetailsClassnames +
                            (hover === "pro" ? " shadow-xl" : " shadow-sm")
                        }
                    >
                        <h1 className="font-display text-2xl font-semibold">
                            Streamlux Pro
                        </h1>
                        <h2 className="font-display text-lg">
                            Tenetur quod quidem in voluptatem corporis dolorum
                            dicta sit pariatur basic!
                        </h2>
                    </div>
                </div>

                <div className="w-1/3">
                    <Paypal plan={plan} />
                </div>
                <div className="w-full overflow-hidden">
                    <svg
                        className="waves"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        viewBox="0 24 150 28"
                        preserveAspectRatio="none"
                        shape-rendering="auto"
                    >
                        <defs>
                            <path
                                id="gentle-wave"
                                d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
                            />
                        </defs>
                        <g className="parallax">
                            <use
                                xlinkHref="#gentle-wave"
                                x="48"
                                y="0"
                                fill="rgba(87, 71, 255,0.7)"
                            />
                            <use
                                xlinkHref="#gentle-wave"
                                x="0"
                                y="3"
                                fill="rgba(87, 71, 255,0.7)"
                            />
                        </g>
                    </svg>
                </div>
            </div>
        </>
    );
};

export default Upgrade;
